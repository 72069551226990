/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-loss/why-you-hear-but-cant-understand/'], ['en', 'https://www.hear.com/resources/hearing-loss/why-you-hear-but-cant-understand/'], ['en-US', 'https://www.hear.com/resources/hearing-loss/why-you-hear-but-cant-understand/'], ['en-CA', 'https://ca.hear.com/resources/hearing-loss/why-you-hear-but-cant-understand/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "why-you-can-hear-but-cannot-understand",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-you-can-hear-but-cannot-understand",
    "aria-label": "why you can hear but cannot understand permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why You Can Hear But Cannot Understand"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever felt like people mumble when they speak or that their words are muffled? Do you find yourself asking people to repeat themselves? If so, you’re not alone. Hearing loss affects our ability to understand speech more than we realize. Keep reading to understand why."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the most common signs of hearing loss is difficulty understanding speech. You might be able to hear that someone is talking but not understand exactly what they are saying. For example, you might confuse “What time is it?” with “What kind is it?”. As a result, everyday activities like watching TV or talking with your family at the dinner table can become less enjoyable and more exhausting."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To demonstrate the point, below is a visual example of “normal hearing” and “hearing loss”, which we’ll explain further."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing_chart.jpg",
    alt: "Normal and hearing loss comparrison",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "certain-sounds-and-letters-can-be-harder-to-hear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#certain-sounds-and-letters-can-be-harder-to-hear",
    "aria-label": "certain sounds and letters can be harder to hear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Certain sounds and letters can be harder to hear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In human speech, there are lower-pitched sounds (A, E, I, O and U) and higher-pitched sounds (S, F, Th, Sh, Ch, K, P, and H). If you can’t hear the higher-pitched consonants, it’s difficult to understand what someone is saying. Vowel sounds help to alert you that speech is present. But it’s the consonant sounds that give speech meaning by distinguishing one word from another. This is why so many people with high-frequency hearing loss, brought about by natural aging or excessive noise exposure, have difficulty understanding even when they know speech is present."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Below is a visual tool, nicknamed the Speech Banana, that illustrates where the sounds of everyday human speech occur. The letters and sounds of the alphabet makes the shape of a banana on an audiogram. As you can see, ", React.createElement(_components.strong, null, "even mild hearing loss can affect the sounds of speech"), ", making it difficult to understand words with consonant sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/speech-banana.png",
    alt: "Speech Banana",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "noisy-environments-make-understanding-even-harder",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#noisy-environments-make-understanding-even-harder",
    "aria-label": "noisy environments make understanding even harder permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Noisy environments make understanding even harder"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "No matter where you find yourself, untreated hearing loss can make understanding speech difficult. However, when you have competing noises and voices in the background, understanding speech is next to impossible. This can make it challenging to communicate effectively in social situations like restaurants, meetings or larger gatherings."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "if-you-can-hear-but-cant-understand-theres-a-solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#if-you-can-hear-but-cant-understand-theres-a-solution",
    "aria-label": "if you can hear but cant understand theres a solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "If you can hear but can't understand, there's a solution"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Medical-grade hearing aids are designed to improve speech understanding. The best way to experience the difference they can make is to try them for yourself. This is why hear.com offers a 45-day trial of the best hearing aids from the leading manufacturers. If you haven’t scheduled your appointment yet, make sure to click the button below."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
